export default {
	auth: {
		welcome_to_online_cash_register: "Добро пожаловать в Онлайн-кассу",
		no_more_cash_register: "Кассовый аппарат Вам больше не нужен",
		open_and_close_shift: "Открывайте и закрывайте смену",
		issue_receipts_in_a_second: "Выбивайте чеки за секунду",
		collect_X_and_Z_reports_in_real_time: "Собирайте X и Z-отчеты в режиме реального времени",
		log_in: "Войти",
		phone_number: "Номер телефона",
		password: "Пароль",
		forgot_password: "Забыли пароль?",
		register: "Зарегистрироваться",
		online_cash_register_from_kassa24_business: "Онлайн-касса от Kassa24 Business"
	},
	registration: {
		online_cash_register: "Онлайн-касса",
		work_with_cash_register: "Работайте с кассой через сайт, мобильного приложения или с помощью первого в Кахахстане фискального чат-бота Telegram",
		online_payments: "Онлайн-платежи",
		accept_payments: "Принимайте оплату на сайте своего Интернет магазина, в мобильном приложении или в соцсетях с помощью банковских карт, электронных кошельков, платежных терминалов или баланса телефона.",
		delivery: "Доставка",
		send_goods: "Отправляйте товары клиентам с помощью курьерской службы Kassa24 и крупнейшей сети постаматов в Казахстане",
		advertising_on_terminals: "Реклама на терминалах",
		promote_your_company: "Продвигайте свою компанию на экранах платежных терминалов Kassa24. Наша сеть насчитывает более 17 000 терминалов по всему Казахстану",
		affiliate_program: "Партнерская программа",
		invite_friends_entrepreneurs: "Приглашайте друзей-предпринимателей присоединиться к системе Kassa24 Business и получайте приятный процент с подключения каждого приглашенного человека",
		more_details: "Подробнее",
		log_in: "Вход",
		register: "Регистрация",
		name: "Имя",
		continue: "Продолжить",
		registering_in_personal_account: "Регистрируясь в Личном кабинете, вы соглашаетесь с условиями Договора комплексного обслуживания"
	},
	main: {
		no_name: "Нет названия",
		cash_register_number: "Касса",
		balance: "Баланс",
		cash: "Наличные",
		cashless: "Безналичные",
		all_reports: "Все отчеты",
		x_report: "Х-отчет",
		shift: "Смена",
		number_of_receipts: "Количество чеков",
		close_shift: "Закрыть смену",
		printed_receipts: "Выбито чеков",
		new_sale: "Новая продажа",
		new_operation: "Новая операция",
		sale: "Продажа",
		purchase: "Покупка",
		new_purchase: "Покупка",
		income: "Приход",
		expense: "Расход",
		expense_and_indexation: "Расход и инкассация",
		refund_of_sale: "Возврат продажи",
		refund_of_purchase: "Возврат покупки",
		refund: "Возврат",
		title_search_refund: "Сделайте поиск по номеру чека",
		info_search_refund: "Номер чека можно посмотреть в самом чеке или Журнале операций",
		info_search_refund_sale: "Чтобы продолжить процесс возврата продажи, сделайте поиск по номеру чека",
		info_search_refund_purchase: "Чтобы продолжить процесс возврата покупки, сделайте поиск по номеру чека",
		go_to_operation_log: "Перейти в журнал операций",
		old_site_view: "Старый вид сайта",
		select_company_and_cash_register: "Выбрать компанию/кассу",
		select_cash_register: "Выбрать кассу",
		search_cash_register: "Поиск кассы",
		list_of_companies: "Список компаний",
		cash_registers: "Кассы",
		znm_abbreviation: "ЗНМ (сокр. от \"Заводской номер машины\")",
		cash_register_blocked_in_k24v: "Касса заблокирована в К24В",
		cash_register_blocked_in_ofd: "Касса заблокирована в ОФД",
		shift_opened_by_another_cashier: "Смена открыта другим кассиром",
		cash_register_available: "Касса доступна",
		your_shift: "Ваша смена",
		payment_required: "Требуется оплата",
		"days_left": "Осталось {{count}} {{count, plural, =1{день} one{день} few{дня} many{дней} other{дней}}}",
    "paid_until_date": "Оплачено до {{date}}",
		old_view: "Старый вид | сайта",
		getting_data: "Получение данных...",
		error: "Ошибка",
		loading: "Загрузка...",
		no_cash: "Нет созданных касс",
		not_found: "По вашему запросу ничего не найдено",
		found_cash: "Найденные кассы",
		cashier: "Кассир",
		copied: "Скопировано",
		reports: "Отчеты",
		dear_user: "Уважаемый пользователь!",
		dear_users: "Уважаемые пользователи!",
		dear_user_text: "Не забывайте пробивать фискальные чеки. Это обязательное требование Налогового кодекса РК.",
		dear_users_text: "Онлайн-касса поддерживает версию протокола передачи данных 2.0.2, согласно требованиям КГД. Перепрограммировать кассу не нужно.",
		close_modal: "Закрыть",
		more: "Узнать подробнее",
		more_mobile: "Подробнее",
	},
	close_shift: {
		attention_closing_shift: "Внимание! Вы собираетесь закрыть смену!",
		shift_closure_results: "Итоги закрытия смены будут отправлены в налоговую.",
		please_check_cash_register_balances: "Пожалуйста, сверьтесь с балансами кассы.",
		cash_funds: "Наличные средства",
		cashless_funds: "Безналичные средства",
		cancel: "Отменить",
		logout: "Выйти",
		closing: "Закрываем",
		close_shift: "Закрыть смену",
		return_to_cash_register: "Вернуться в кассу",
		back: "Назад",
		z_report: "Архив Z-отчет",
		z_report_error: "Ошибка закрытия смены",
		shift: "Смена",
		print: "Распечатать",
		section: "Секция:",
		sales: "Продажи",
		sales_return: "Возврат продажи",
		purchases: "Покупки",
		purchase_refunds: "Возврат Покупок",
		section_report: "Отчет по секциям",
		no_operations: "Операций не производилось",
		service_income: "Служебный приход",
		service_expense: "Служебный расход",
		purchase_returns: "Возвраты покупок",
		operation_list: "Список операций",
		operation_type: "Тип операции",
		date: "Дата",
		receipt_number: "Номер чека",
		amount: "Сумма, ₸",
		number_of_receipts: "Кол-во чеков",
		total_amount: "Общая сумма",
		no_receipts: "Нет чеков",
		main_information: "Основная информация",
		organization_name: "Наименование организации",
		tax_id: "ИИН/БИН",
		kkm: "ККМ",
		kgd_registration_number: "Регистрационный номер | в КГД",
		cash_register_name: "Название кассы",
		trading_point_address: "Адрес торговой точки",
		cashier: "Кассир",
		balance_at_opening: "Баланс на момент открытия смены",
		balance_at_closing: "Баланс на момент закрытия смены",
		issued_receipts_alt: "Оформлено чеков",
		issued_receipts: "Оформленные чеки",
	},
	error: {
		logout: "Выйти",
		no_access_to_cash_register: "Нет доступа к кассе!",
		contact_tech_support: "Обратитесь в тех. поддержку:",
		back_to_cash_register_list: "Назад к списку касс"
	},
	kassa: {
		back_to_cash_register_selection: "Назад к выбору касс",
		cash_register_for: "Касса для ...",
		active: "Активна",
		active_status: "Активные",
		not_active_status: "Неактивные",
		all_status: "Все",
		shift_stats: "Статистика за смену, ₸",
		shift_stats_explanation: "Сумма успешных платежей и возвратов за текущую смену.",
		shift_stats_explanation_alt: "В карточках ниже стрелочкой показан тренд - как изменились выручка, возвраты и средний чек по сравнению с предыдущей сменой. И разница в процентах.",
		updated_at: "Обновлена в",
		revenue: "Выручка",
		refunds: "Возвраты",
		average_receipt: "Средний чек",
		make_operation_to_see_chart: "Совершите операцию, чтобы увидеть график",
		top_sales_of_shift: "Топ продаж за смену",
		product_name: "Название товара",
		quantity: "Количество",
		amount: "Сумма, ₸",
		make_operation_to_see_top_sales: "Совершите операцию, чтобы увидеть топ продаж",
		new_shift: "Новая смена",
		cash_resources: "Наличные средства:",
		non_cash_resources: "Безналичные средства:",
		cash_register: "Касса",
		company: "Компания",
		address: "Адрес",
		check_for_log: "Чеков за смену",
		cash: "Наличными",
		non_cash: "Безналичными",
		transfer: "Перевод",
		shift: "Смена",
		operations_log: "Журнал | операций",
		all_reports: "Все отчеты",
		settings: "Настройки",
		close_shift: "Закрыть смену",
		sales: "Продажи",
		total: "Итого",
		start_issuing_receipts: "Начать выбивать чеки",
		in_progress: "В процессе регистрации",
		blocked: "Заблокирована",
		trade: 'Торговля',
		services: 'Услуги',
		hotels: 'Отели',
		parking: 'Паркинг'
	},
	report: {
		receipt_data_unavailable: "Данные чека недоступны",
		back: "Назад",
		reports: "Отчеты",
		last_shift: "Последняя смена:",
		operations_log: "Журнал операций",
		export_to_excel: "Экспорт в Excel",
		no_operations_found_for_current_date: "За текущую дату операции не найдены",
		x_report: "X-отчет",
		z_report_archive: "Архив Z-отчетов",
		by_sections: "По секциям",
		no_z_reports_found_for_current_date: "За текущую дату Z-отчеты не найдены",
		print: "Распечатать",
		section_totals_for_shift: "Итоги по секциям за смену",
		section: "Секция:",
		sales: "Продажи",
		sales_refunds: "Возврат продаж",
		purchases: "Покупки",
		purchase_refunds: "Возврат покупок",
		operation_log: "Журнал операций",
		duplicate: "Дубликат",
		return: "Возврат",
		operation_type: "Тип операции",
		date: "Дата",
		receipt_number: "Номер чека",
		document_number: "Номер документа",
		search_on_receipt_number: "Поиск по номеру чека",
		cash: "Наличными, ₸",
		non_cash: "Безналичными, ₸",
		transfer: "Перевод, ₸",
		total: "Итого, ₸",
		main_information: "Основная информация",
		issued_receipts: "Оформленные чеки",
		organization_name: "Наименование организации",
		service_income: "Служебный приход",
		number_of_receipts: "Кол-во чеков",
		tax_id: "ИИН/БИН",
		total_amount: "Общая сумма",
		kkm: "ККМ",
		kgd_registration_number: "Регистрационный номер | в КГД",
		service_expense: "Служебный расход",
		cash_register_name: "Название кассы",
		trading_point_address: "Адрес торговой точки",
		cashier: "Кассир",
		balance_at_opening: "Баланс на момент открытия смены",
		balance_at_closing: "Баланс на момент закрытия смены",
		sales_returns: "Возвраты продаж",
		issued_receipts_alt: "Оформлено чеков",
		purchase_returns: "Возвраты покупок",
		sale: "Продажа",
		expenditure_and_encashment: "Расход и инкассация",
		sales_return: "Возврат продажи",
		shift_number: "№ смены",
		open: "Открыта",
		closed: "Закрыта",
		received: "Принято:",
		handed_over: "Сдано:",
		operation_count: "Кол-во операции",
		error: "Ошибка",
		start_issuing_receipts: "Начать выбивать чеки",
		basic_information: "Основная информация",
		identification_number: "ИИН/БИН",
		cash_register_machine: "ККМ (Контрольно-кассовая машина)",
		registration_number_in_tax_committee: "Регистрационный номер в КГД (Комитет государственных доходов)",
		point_of_sale_address: "Адрес торговой точки",
		balance_at_shift_opening: "Баланс на момент | открытия смены",
		registered_receipts: "Оформленные чеки",
		no_receipts: "Нет чеков",
		section_report: "Отчет по секциям",
		operation_list: "Список операций",
		no_operations: "Операций не производилось",
		shift_closure: "Закрытие смены",
		page: "Перейти на стр.",
		shift: "Смена",
		operations: "Операций:",
		purchase: "Покупка",
		income: "Приход",
		expense_and_indexation: "Расход и инкассация",
		refund_of_purchase: "Возврат покупки",
		received_alt: "Принято, ₸",
		handed_over_alt: "Сдано, ₸",
		today: "Сегодня",
		yesterday: "Вчера",
		three_days: "3 дня",
		week: "Неделя",
		month: "Месяц",
		quarter: "Квартал",
		half_year: "Полгода",
		year: "Год",
		select_period: "Выбрать период",
		duplicate_z_report: "Дубликат Z-отчётов",
		z_report_print: "Z-отчёт",
		amount: "Сумма, ₸",
		new_sale: "Новая продажа",
	},
	settings: {
		settings: "Настройки",
		settings_unavailable: "Настройки недоступны",
		settings_close_shift: "Чтобы перейти в настройки данной кассы, необходимо закрыть смену",
		shift_opened_by_cashier: "Смена открыта кассиром:",
		on_cash_register: "На кассе:",
		close_shift: "Закрыть смену",
		back: "Назад",
	},
	open_kassa: {
		start_issuing_receipts: "Начать выбивать чеки",
	},
	duplicate: {
		select_receipt_language: "Выбрать язык чека",
		duplicate_receipt: "Дубликат чека",
		send_receipt_to_email: "Отправить чек на Email",
		send: "Отправить",
	},
	kassa_settings: {
		cash_management: "Управление кассой",
		name: "Название",
		place_of_use: "Место использования",
		automatic_shift_closure: "Автозакрытие смены",
		shift_will_be_closed_automatically: "Касса будет закрыта автоматически в период с 23:45 по 23:59",
		save_changes: "Сохранить изменения",
		cash_settings_saved: "Настройки кассы сохранены",
		ofd_settings: "Настройки ОФД",
		sections: "Секции",
		cashiers: "Кассиры",
		nom: "Номенклатура",
		ofd_settings_saved: "Настройки ОФД сохранены",
		address: "Адрес",
		saving_changes: "Сохраняем изменения",
		ofd: "ОФД",
		rnm: "РНМ",
		kkm_id: "ККМ id",
		token: "Токен",
		section_alt: "секция",
		sections_alt: "секции",
		search: "Поиск",
		add_section: "Добавить секцию",
		vat_rate: "Размер НДС",
		actions: "Действия",
		disable: "Отключить",
		goods_without_vat: "Товары без НДС",
		vat: "НДС",
		enable: "Включить",
		cashier_alt: "кассир",
		cashier_alt_2: "кассира",
		cashiers_alt: "кассиров",
		block: "Заблокировать",
		unblock: "Разблокировать",
		items: "21 товар",
		add_item: "Добавить товар",
		import_items: "Импорт товаров",
		barcode: "Штрихкод",
		unit_of_measurement: "Ед. измерения",
		price: "Цена",
		section: "Секция",
		discount: "Скидка",
		markup: "Наценка",
		in_sale: "В продаже",
		edit: "Изменить",
		yes: "Да",
		no: "Нет",
		piece: "штука",
		cubic_centimeter: "сантиметр кубический",
		not_found: "Секции не найдены. Добавьте нажав | на кнопку “+ Добавить товар”",
		enter_name: "Введите название",
		add: "Добавить",
		cashier: "Кассир",
		product_name: "Название товара",
		price_alt: "Цена, ₸",
		markup_alt: "Наценка, %",
		discount_alt: "Скидка, %",
		redact: "Редактировать",
		item: "товар",
		item_alt: "товара",
		items_alt: "товаров",
		choose_file: "Выберите файл или перетащите его сюда",
		download_template: "Необходимо скачать и заполнить список номенклатуры.",
		results: "Результаты по загруженному списку будут доступны в таблице.",
		download_excel_template: "Скачать шаблон в Excel",
		cancel: "Отмена",
		save: "Сохранить",
		item_editor: "Редактор товара",
		add_new_item: "Добавить новый товар",
		enter_barcode: "Введите штрихкод",
		not_displayed: "Товар не будет отображаться в выпадающем списке на странице оформления продажи",
		selection_list: "На странице оформления продажи, товар можно выбрать из выпадающего списка и все данные заполнятся автоматически",
		kkm_sections: "Секции ККМ",
		status: "Статус",
		active: "Активна",
		disabled: "Отключена",
	},
	add_nom: {
		add_new_item: "Добавить новый товар",
		barcode: "Штрих-код",
		scan_barcode: "Отсканируйте штрихкод продукции",
		enter_barcode: "Введите штрихкод",
		enter_name: "Введите название",
		unit_of_measurement: "Единица измерения",
		unit_options: [
			"штука",
			"миллиграмм",
			"грамм",
			"килограмм",
			"тонна",
			"миллилитр",
			"литр",
			"сантиметр",
			"метр",
			"сантиметр квадратный",
			"метр квадратный",
			"сантиметр кубический",
			"метр кубический",
			"метр погонный",
			"упаковка",
			"пара",
			"одна услуга",
			"час",
			"сутки",
			"месяц",
			"комплект"
		],
		available_for_sale: "Товар доступен для продажи",
		not_displayed: "Товар не будет отображаться в выпадающем списке на странице оформления продажи",
		selection_list: "На странице оформления продажи, товар можно выбрать из выпадающего списка и все данные заполнятся автоматически",
		close: "Закрыть",
		save: "Сохранить",
		item_editor: "Редактор товара",
		choose_file: "Выберите файл или перетащите его сюда",
		file_size_limit: "Вес файла не более 10 мБ, в формате Excel (.xls)",
		download_template: "Необходимо скачать и заполнить список номенклатуры. Результаты по загруженному списку будут доступны в таблице.",
		download_excel_template: "Скачать шаблон в Excel",
	},
	receipt: {
		old_view: "Старый вид",
		return_to_cash_register: "Вернуться в кассу",
		new_sale: "Новая продажа",
		new_operation: "Новая операция",
		simplified_mode: "Упрощенный режим",
		add_item: "Добавление позиции",
		tota_amount: "Оплата",
		clear_form: "Очистить форму",
		item_name: "Наименование товара",
		price: "Цена, ₸",
		quantity: "Количество",
		unit_of_measurement: "Единица измерения",
		excise_goods: "Подакцизный товар",
		section: "Секция",
		markup: "Наценка",
		discount: "Скидка",
		add_another: "Добавить ещё",
		precheck: "Предчек",
		reset_precheck: "Сбросить предчек",
		payment: "Оплата",
		cash: "Наличными, ₸",
		card: "Картой, ₸",
		discount_rate: "Скидка 0.00% = 0 ₸",
		markup_rate: "Наценка 0.00% = 0 ₸",
		vat: "НДС 10%",
		customer_tax: "ИИН/БИН покупателя",
		customer_tax_id: "ИИН/БИН | покупателя",
		total_to_pay: "К оплате",
		change: "Сдача",
		issue_receipt: "Оформить чек",
		select_receipt_language: "Выбрать язык чека",
		operation_successful: "Операция прошла успешно",
		russian: "Русский",
		send_receipt_email: "Отправить чек на Email",
		send_receipt_whatsapp: "Отправить чек на WhatsApp",
		send: "Отправить",
		print: "Распечатать",
		to: "ТОО Moon (название компании)",
		bin: "БИН (сокр. от 'бизнес-идентификационный номер')",
		cashier: "Кассир",
		sale: "Продажа",
		cost: "Стоимость",
		no_vat: "БЕЗ НДС",
		discount_alt: "Скидка",
		including_vat: "В т.ч. НДС (сокр. от 'в том числе')",
		total: "ИТОГ",
		cash_alt: "Наличными",
		non_cash: "Безналичными",
		kkm_address: "Адрес ККМ",
		rnm: "РНМ (сокр. от 'Регистрационный номер машины')",
		znm: "ЗНМ (сокр. от 'Заводской номер машины')",
		fiscal_code: "Фискальный код",
		ofd: "ОФД (сокр. от 'Оператор фискальных данных')",
		website: "Сайт",
		ks_registered_number: "КС (касса) внесена в Госреестр РК под №182",
		check_by_link: "Проверьте чек по ссылке",
		receipt: "Чек",
		successful_operation: "Операция прошла успешна",
		back: "Назад",
		reports: "Отчеты",
		last_shift: "Последняя смена 11",
		operation_log: "Журнал операций",
		x_report: "X-отчет",
		z_report_archive: "Архив Z-отчетов",
		by_sections: "По секциям",
		duplicate: "Дубликат",
		export_to_excel: "Экспорт в Excel",
		online_cash_register: "Касса онлайн",
		operation_type: "Тип операции",
		date: "Дата",
		receipt_number: "Номер чека",
		amount: "Сумма, ₸",
		reset: "Сбросить",
		shift_closure: "Закрытие смены",
		return: "Возврат",
		purchase: "Покупка",
		copy_precheck: "Скопировать предчек",
		go_to_page: "Перейти на стр.",
		online_cash_register_kassa24: "Онлайн-касса от Kassa24 Business",
		edit: "Редактирование",
		kg: "Кг.",
		save_changes: "Сохранить изменения",
		changed_at: "Изменено в 15:29",
		changes_saved: "Изменения сохранены",
		receipt_template: "Шаблон чека",
		select_template: "Выберите шаблон",
		template_1: "Шаблон 1",
		save_precheck_template: "Сохранить шаблон предчека",
		delete_template_1: "Удалить “Шаблон 1”?",
		delete: "Удалить",
		cancel: "Отмена",
		save_receipt_template: "Сохранить шаблон чека",
		template_name: "Название шаблона",
		enter_name: "Введите название",
		save: "Сохранить",
		close: "Закрыть",
		scan_barcode: "Отсканируйте штрихкод продукции",
		item: "позиция",
		items: "позиции",
		markup_alt: "Наценка",
		transfer: "Перевод, ₸",
		cash_alt_2: "Наличные",
		transfer_alt: "Перевод",
		card_alt: "Карта",
		refund_of_sale: "Возврат продажи",
		refund_of_purchase: "Возврат покупки",
		service_income: "Служебный приход",
		income_amount: "Сумма прихода, ₸",
		expense_amount: "Сумма расхода, ₸",
		service_expense: "Служебный расход",
		printing: "Печать",
	},
	discount_type: {
		label: "Выберите тип скидки/наценки",
		percent: "Процентная",
		fixed: "Фиксированная"
	},
	num: {
		add_product: "Добавить товар",
		add_new_product: "Добавить новый товар",
		required_field: "Обязательное поле",
		barcode: "Штрихкод",
		enter_barcode: "Введите штрихкод",
		product_name: "Название товара",
		unit_piece: "штука",
		product_available_for_sale: "Товар доступен для продажи",
		product_not_displayed_in_dropdown: "Товар не будет отображаться в выпадающем списке на странице оформления продажи",
		add: "Добавить"
	},
	translation: {
		translation: "Перевод",
	},
	other: {
		technical_break: "Технический перерыв",
		apologies_for_disruptions: "Приносим извинения за перебои в работе Онлайн-кассы",
		on_support: "Наши технические специалисты уже работают над устранением неполадок.",
		registration_in_progress: "Касса в процессе регистрации",
		k24b_blocked: "Касса заблокирована в K24B", // TODO
		reports: "Отчёты", // TODO
		unpaid_cashier: "Касса не оплачена", // TODO
		open_shift_blocked_ofd: "Смена на кассе открыта, но касса заблокирована в ОФД", // TODO
		device_blocking: "Касса заблокирована из-за работы с разных устройств", // TODO
		expired_shift: "Смена просрочена", // TODO
		incorrect_device_time: "Время на вашем устройстве не соответствует действительному", // TODO
		open_shift_on_another_cashier: "У вас уже есть открытая смена на другой кассе", // TODO
		open_shift_ofd_block: "Смена на кассе открыта, но касса заблокирована в ОФД", // TODO
		failed_testing: "Ошибка тестирования",
		contact_support: "Связаться с технической поддержкой",
		cash_register_info: "Информация о кассе",
		analytics: "Аналитика",
		shift_receipts: "Чеков за смену", // TODO
		close_cash_register: "Закрытие кассы", // TODO
		return_to_cash_register: "Вернуться на кассу", // TODO
		operation_failed_device_blocking: "Операция не удалась. Касса заблокирована из-за работы с разных устройств",
		open_shift: "Открыть смену", // TODO
		testing_error: "Ошибка тестирования",
		not_specified: "Не указано",
		no_operations_in_cash_register: "В данной кассе не проведено ни одной | операции",
		click_for_details: "Нажмите, чтобы посмотреть подробнее",
		no_open_shift_for_x_report: "У данной кассы нет открытой смены | для отображения X-отчета",
		balance_at_x_report_time: "Баланс на момент | формирования Х-отчета",
		no_open_shift_for_section_report: "У данной кассы нет открытой смены для | отображения отчета по секциям",
		no_completed_shifts_for_z_reports: "У данной кассы нет завершенных смен для | отображения Z-отчетов",
		switch_advanced_simple_mode_warning: "При переходе на расширенный режим | упрощённый режим | вы потеряете все данные",
		confirm_switch_mode: "Вы действительно хотите перейти на | расширенный режим | упрощённый режим",
		cancel: "Нет, отменить",
		proceed: "Да, перейти",
		simplified_mode: "Упрощенный режим",
		enter_name: "Укажите наименование",
		enter_amount: "Укажите сумму",
		activity_type: "Вид деятельности",
		add_to_precheck: "Добавить в предчек",
		excise_code: "Акцизный код",
		add_position: "Добавить позицию",
		empty_precheck: "Предчек пуст",
		add_items_on_the_left_block: "Добавьте товары в блоке слева",
		precheck_refund_description: "Найдите нужный чек в строке поиска",
		non_cash_payments_received: "Безналичные платежи за товар / услугу, получаемые ч/з моб. приложение / др. устройство, предназначенное для приема платежей при помощи штрих-кода (Закон РК от 31.12.2021 №100-VII)",
		iin_bin_optional: "ИИН/БИН опциональный, указывается по запросу",
		enter_valid_bin_iin: "Введите корректный БИН/ИИН",
		insufficient_funds_for_operation: "Для проведения операции не хватает",
		purchase_of_goods: "Покупка товара",
		section_successfully_added: "Секция успешно добавлена",
		item_successfully_updated: "Товар успешно обновлен",
		item_successfully_added: "Товар успешно добавлен",
		enter_cash_register_name: "Пожалуйста укажите название кассы",
		enter_section_name: "Пожалуйста, укажите название секции",
		enter_vat: "Пожалуйста, укажите ндс",
		add_new_section: "Добавление новой секции",
		section_name: "Название секции",
		cashiers_not_found: "Кассиры не найдены.",
		you_can_add_cashiers_in_kassa24_business_account_go_to_cash_register_settings: "Добавить кассиров можно в личном кабинете Kassa24 Business, | перейдя в настройки кассы",
		no_items_found_add_by_clicking_add_item: "Товары не найдены. Добавьте нажав на | кнопку '+Добавить товар'",
		uploaded_files: "Загруженные файлы",
		invalid_file_format: "Неверный формат файла",
		file_too_large: "Файл слишком велик",
		file_size_limit: "Вес файла не более 10 мБ, в формате Excel (.xls)",
		uploading_your_file: "Загружаем ваш файл",
		rows_not_loaded_edit_file_and_upload_again: "Указанные строки не были загружены, отредактируйте файл и загрузите его снова",
		successfully_imported_items: "Успешно импортировано товаров",
		enter_item_name: "Пожалуйста, укажите название товара",
		enter_item_price: "Пожалуйста, укажите цену товара",
		barcode_length_8_or_13: "Длина штрихкода должна быть 8 или 13",
		access_restricted: "Доступ ограничен",
		programming_mode_available_only_with_closed_shift: "Режим программирования доступен только при закрытой смене на кассе.",
		close_shift_to_access_this_mode: "Необходимо закрыть смену на кассе для доступа к данному режиму.",
		return_to_operation_mode: "Вернуться в режим операций",
		check_email_correctness: "Проверьте правильность Email",
		check_email_sent_successfully: "Чек успешно отправлен на email",
		enter_email: "Укажите Email",
		check_phone_number_correctness: "Проверьте правильность номера телефона",
		check_sent_successfully_to_number: "Чек успешно отправлен на номер",
		enter_phone_number: "Укажите номер телефона",
		check_sent_for_printing: "Чек отправлен на печать",
		add_new_section_alt: "Добавить новую секцию",
		enter_password: "Введите пароль",
		enter_phone: "Введите телефон",
		at_least_6_characters: "Не менее 6 символов",
		incorrect_login_or_password: "Неверный логин или пароль",
		your_shift: "Ваша смена",
		cash_register_available: "Касса доступна",
		shift_opened_by_another_cashier: "Смена открыта другим кассиром",
		cash_register_blocked_in_ofd: "Касса заблокирована в ОФД",
		cash_register_blocked_in_ofd_kassa: "Касса заблокирована в ОФД и в K24B",
		go_to_page: "Перейти на стр.",
		purchase_refund_warning_text: 'Операция, которую Вы ищете является Покупкой. Если Вы хотите сделать возврат Покупки, пожалуйста, перейдите в раздел "Возврат покупки"',
		sale_refund_warning_text: 'Операция, которую Вы ищете является Продажей. Если Вы хотите сделать возврат Продажи, пожалуйста, перейдите в раздел "Возврат продажи"',
		go_to_purchase_refund: "Перейти к возврату покупки",
		go_to_sale_refund: "Перейти к возврату продажи",
		operation_can_not_be_refunded: "Операция с указанным номером чека не может быть возвращена. Пожалуйста, проверьте номер чека и попробуйте снова",
	}
}
