import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import axios from "axios";
import { format, subDays } from "date-fns";

import style from '../../styles/pages/report/tabs/NewReportOperations.module.scss'
import {BASE_URL, formatDate, getHeaders} from "../../../../Store/snippets";
import NewPagination from "../../NewSettings/NewPagination";
import FileExcel2FillBig from '../../svg/fill-excel-2-fill-big.svg?react'
import LoaderLineBlack from '../../svg/loader-line--black.svg?react'
import Wallet from '../../svg/wallet-small.svg?react'
import Card from '../../svg/card-small.svg?react'
import MobileTransfer from '../../svg/mobile-transfer.svg?react'
import { downloadXLSFile } from '../../../../Api/api';
import NewCalendar from "../../NewCalendar";
import NewReportOperationsModal from "../modals/NewReportOperationsModal";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import { getRangePeriod } from "../../../../Utils/calendar";
import SearchField from '../../NewCompanies/SearchField';
import ArrowDownSFill from "../../svg/arrow-down-s-fill.svg?react";
import ArrowUpSFill from "../../svg/arrow-up-s-fill.svg?react";
import { SORT_TYPES } from '../../../../constants/sort-table';
import write_blob from 'capacitor-blob-writer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { shareFile } from '../../../../Utils/share-file';
import { OPERATION_TYPES } from '../../../../constants/app';

const NewReportOperations = ({reportOperations, idKkm, filterReportOperationByDate, filterReportData, weekdayFromToday, 
	today, calendarIsAvailableForOperations, reportOperationModalInfo, showReportOperationsDuplicate, 
	resetReportOperationsDuplicate, getReportOperations, checkReportOperations, isLoading }) => {

	const { t } = useTranslation()

	const history = useHistory()
	const headers = getHeaders(history)
	const [activePage, setActivePage] = useState(1)
	const [reportOperationsList, setReportOperationsList] = useState(reportOperations.slice(0, 10))
	const [calendarOptionsIsOpen, setCalendarOptionsIsOpen] = useState(false)
	const [calendarIsOpen, setCalendarIsOpen] = useState(false)
	const [searchedValue, setSearchedValue] = useState('')

	const [datePeriod, setDatePeriod] = useState({
		from: formatDate(weekdayFromToday, 2),
		to: formatDate(today, 2)
	})

	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: subDays(new Date(), 7),
			key: 'selection',
		}
	]);

	const [isPending, setIsPending] = useState(false)
	const [showReportOperationsModal, setShowReportOperationsModal] = useState(false)
	const [selectedOperationId, setSelectedOperationId] = useState(0)

	// Sorting
	const [sortingIsClicked, setSortingIsClicked] = useState(false)
	const [sortingIsAscending, setSortingIsAscending] = useState({
		[SORT_TYPES.DATE]: false,
		[SORT_TYPES.NUM_CHECK]: false,
	});
	const [typeToSort, setTypeToSort] = useState('')

	useEffect(() => {
		filterReportData(typeToSort, sortingIsAscending)
	}, [sortingIsAscending, typeToSort])

	useEffect(() => {
		getReportOperations()

		checkReportOperations()
	}, [])

	useEffect(() => {
		setReportOperationsList(reportOperations.slice(10 * (activePage-1), 10 * activePage))
	}, [reportOperations, activePage])

	const setPage = (page) => {
		setActivePage(page)
		setReportOperationsList(reportOperations.slice(10 * (page-1), 10 * page))
	}

	const goToFund = (id, type) => {
		setSelectedOperationId(id)
		setIsPending(true)
		axios.get(`${BASE_URL}/operations/${id}`, { headers })
			.then(response => {
				const documentData = response.data.Data.Document;
				const positionsData = documentData.Positions;
				const isCashTransaction = documentData.Cash > 0;

				localStorage.setItem('PositionDetailsFromReport', JSON.stringify(positionsData))
				localStorage.setItem('PositionDetailsIsCashTransaction', JSON.stringify(isCashTransaction))
				localStorage.setItem('OperationIdFromReport', id)
				setIsPending(false)
				type === OPERATION_TYPES.SALE.ID ? history.push(`/www/kkms/${idKkm}/refund_new`) : history.push(`/www/kkms/${idKkm}/purchase_refund_new`)
			})
			.catch(e => console.log(e))
	}

	const getPeriod = (type, selectedDateRange) => {
		setActivePage(1)

		const { date, dateISOstring } = getRangePeriod(type, selectedDateRange, 'Z');

		setDatePeriod({
			...date,
		})

		setCalendarOptionsIsOpen(false)
		filterReportOperationByDate(dateISOstring)
	}

	const printCheck = async (check) => {
		try {
		  if (Capacitor.isNativePlatform()) {
			const fileName = `Чек-${check.id}.png`;
	
			await Filesystem.writeFile({
			  directory: Directory.Documents,
			  path: fileName,
			  data: "data:image/png;base64, " + check.img,
			});
	
			const fileUri = await Filesystem.getUri({
			  directory: Directory.Documents,
			  path: fileName,
			});
	
			await shareFile(fileName, fileUri.uri, `Чек-${check.id}`);
		  } else {
			const receipt = document.getElementById("reciept");
			const printWindow = window.open("");
			printWindow.document.write(receipt.outerHTML);
			printWindow.print();
		  }
		} catch (e) {
		  console.error("Error downloading or saving file:", e);
		}
	  };

	  const downloadFile = async (date_from, date_to) => {
      const [day, month, year] = date_to.split('.');
      const [day_from, month_from, year_from] = date_from.split('.');

      try {
        const res = await downloadXLSFile(idKkm, {
          date_from: format(new Date(year_from, month_from - 1, day_from), "yyyy-LL-dd'T'HH:mm:ss"),
          date_to: format(new Date(year, month - 1, day, 23, 59, 59), "yyyy-LL-dd'T'HH:mm:ss"),
        });

        if (!res.data || res.data.length === 0) {
          throw new Error('No data received from the server.');
        }

        const timestamp = new Date().getTime();
        const outputFilename = `Журнал_операций_${date_from}-${date_to}-${timestamp}.xlsx`;
        const blob = new Blob([res.data]);

        if (Capacitor.isNativePlatform()) {
          await write_blob({
            path: outputFilename,
            directory: Directory.Documents,
            blob: blob,
          });

          const fileUri = await Filesystem.getUri({
            directory: Directory.Documents,
            path: outputFilename,
          });

          await shareFile(outputFilename, fileUri.uri);
        } else {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        console.error('Error downloading or saving file:', e);
      }
    };

	const handleSearch = () => {
		const filteredList = reportOperations.filter((reportOperation) => 
				String(reportOperation.Id).includes(searchedValue.toLowerCase())
		).slice(0, 10);

		setActivePage(1);
		setReportOperationsList(filteredList);
	}

	const filterReport = (type) => {
		setSortingIsAscending({
			...sortingIsAscending,
			[type]: !sortingIsAscending[type],
		});
		setTypeToSort(type);
		setSortingIsClicked(true);
	};

	const toggleSortingIcon = (type) => {
		if (!sortingIsClicked) {
			return (
				<ArrowDownSFill/>
			)
		}

		if (typeToSort === type && sortingIsClicked) {
			return (
				<React.Fragment>
					{ sortingIsAscending[type] ? <ArrowUpSFill/> : <ArrowDownSFill/>}
				</React.Fragment>
			)
		}
	}

	const reportOperationsRenderList = reportOperationsList.map((reportOperation) => {
		return (
			<div
				className={style['table__list']}
				key={reportOperation.Id}
			>
				<div className={style['table__item']}>
					<span>{reportOperation.TypeDocument.Name}</span>
				</div>
				<div className={style['table__item'] + ' ' + style['table__item_date']}>
					<span>{formatDate(new Date(reportOperation.DateDocument))}</span>
				</div>
				<div className={style['table__item']}>
					<span>{reportOperation.Id}</span>
				</div>
				<div className={style['table__item']}>
					<span>{ Number(reportOperation.Cash).toFixed(2) }</span>
				</div>
				<div className={style['table__item']}>
					<span>{ Number(reportOperation.NonCash).toFixed(2) }</span>
				</div>
				<div className={style['table__item']}>
					<span>{ Number(reportOperation.Value).toFixed(2) }</span>
				</div>
				<div className={style['table__item']}>
					{reportOperation.TypeDocument.Id !== 7 &&
					<button
						className={`${style.btn} ${style['btn--off']}`}
						onClick={() => {
							setShowReportOperationsModal(true)
							showReportOperationsDuplicate(reportOperation.Id)
							setSelectedOperationId(reportOperation.Id)
						}}
					>
						<span>{ t('report.duplicate') }</span>
						{ showReportOperationsModal && !reportOperationModalInfo.status && (selectedOperationId === reportOperation.Id) && <LoaderLineBlack/>}
					</button>}
					{(reportOperation.TypeDocument.Id === OPERATION_TYPES.SALE.ID || reportOperation.TypeDocument.Id === OPERATION_TYPES.PURCHASE.ID) &&
					<button
						className={`${style.btn} ${style['btn--on']} ${style['ml-10']}`}
						onClick={() => goToFund(reportOperation.Id, reportOperation.TypeDocument.Id)}
					>
						<span>{ t('report.return') }</span>
						{ isPending  && (selectedOperationId === reportOperation.Id) && <LoaderLineBlack/>}
					</button>}
				</div>
			</div>
		)
	})

	const reportOperationsRenderListMobile = reportOperationsList.map((reportOperation) => {
		return (
			<div
				className={style['table__list']}
				key={reportOperation.Id}
			>
				<div className={style['table__item']}>
					<span>{reportOperation.TypeDocument.Name}</span>
				</div>
				<div className={style['table__item']}>
					<span>{formatDate(new Date(reportOperation.DateDocument))}</span>
				</div>
				<div className={style['table__itemWrapper']}>
					<div className={style['table__item']}>
						<Wallet />
						<span>{ Number(reportOperation.Cash).toFixed(2) } ₸</span>
					</div>
					<div className={style['table__item']}>
						<Card />
						<span>{ Number(reportOperation.NonCash).toFixed(2) } ₸</span>
					</div>
				</div>
				<div className={style['table__item']}>
					<div className={style['table__name-text']}>
						<span>{reportOperation.Id  || t('other.not_specified')}</span>
					</div>
				</div>
				<div className={style['table__item']}>
					<span>{ Number(reportOperation.Value).toFixed(2) } ₸</span>
				</div>
				<div className={style['table__item']}>
					{reportOperation.TypeDocument.Id !== OPERATION_TYPES.CLOSE_SHIFT.ID && 
					 reportOperation.TypeDocument.Id !== OPERATION_TYPES.CLOSE_SHIFT_WITHDRAWAL.ID &&
						<button
							className={`${style.btn} ${style['btn--off']}`}
							onClick={() => {
								setShowReportOperationsModal(true)
								showReportOperationsDuplicate(reportOperation.Id)
								setSelectedOperationId(reportOperation.Id)
							}}
						>
							<span>{ t('report.duplicate') }</span>
							{ showReportOperationsModal && !reportOperationModalInfo.status && (selectedOperationId === reportOperation.Id) && <LoaderLineBlack/>}
						</button>}
					{(reportOperation.TypeDocument.Id === OPERATION_TYPES.SALE.ID || reportOperation.TypeDocument.Id === OPERATION_TYPES.PURCHASE.ID) &&
						<button
							className={`${style.btn} ${style['btn--on']} ${style['ml-10']}`}
							onClick={() => goToFund(reportOperation.Id, reportOperation.TypeDocument.Id)}
						>
							<span>{ t('report.return') }</span>
							{ isPending  && (selectedOperationId === reportOperation.Id) && <LoaderLineBlack/>}
						</button>}
				</div>
			</div>
		)
	})

	return (
		<React.Fragment>
			{isLoading ? 
				<Loader />
				: 
				<React.Fragment>
					<div className={style.table}>
						<div className={`${style['my-20']} ${style.head}`}>
							<SearchField
									searchValue={searchedValue}
									setSearchValue={setSearchedValue}
									searchOpen
									setSearchOpen={() => {}}
									onSearchSubmit={handleSearch}
									placeholder={t('report.search_on_receipt_number')}
							/>
							<div className={style['table__top']}>
								<NewCalendar
									setCalendarOptionsIsOpen={setCalendarOptionsIsOpen}
									calendarOptionsIsOpen={calendarOptionsIsOpen}
									datePeriod={datePeriod}
									getPeriod={getPeriod}
									dateRange={dateRange}
									setDateRange={setDateRange}
									calendarIsOpen={calendarIsOpen}
									setCalendarIsOpen={setCalendarIsOpen}
								/>
								<button
									disabled={!reportOperations.length}
									className={`${style.btn} ${style['btn--primary']} ${style['ml-20']}`}
									onClick={()=> downloadFile(datePeriod.from, datePeriod.to)}
									>
										<FileExcel2FillBig/>
										<span className={style['ml-10']}>{ t('report.export_to_excel') }</span>
								</button>
							</div>
						</div>
						{ reportOperations.length
							?
							<div className={`${style.card} ${style['card--white-nopadding']}`}>
								<div className={style['table__content']}>
									<div className={style['table__header']}>
										<div>{ t('report.operation_type') }</div>
										<div onClick={() => filterReport(SORT_TYPES.DATE)}>
											{t('report.date')} {toggleSortingIcon(SORT_TYPES.DATE)}
										</div>
										<div onClick={() => filterReport(SORT_TYPES.NUM_CHECK)}>
											{t('report.receipt_number')} {toggleSortingIcon(SORT_TYPES.NUM_CHECK)}
										</div>
										<div>{ t('report.cash') }</div>
										<div>{ t('report.non_cash') }</div>
										<div>{ t('report.total') }</div>
										<div></div>
									</div>
									<div className={style['table__body--desktop']}>
										{reportOperationsRenderList}
									</div>
									<div className={style['table__body--mobile']}>
										{reportOperationsRenderListMobile}
									</div>
									{
										reportOperationsList.length ? (
											<NewPagination
												setPage={setPage}
												list={reportOperations}
												activePage={activePage}
											/>
										) : null
									}
								</div>
							</div>
							:
							<div className={`${style.card} ${style['card--white-nopadding']} ${style['mt-20']}`}>
								<div className={`${style['text-center']} ${style['pa-40']}`}>
									{ !calendarIsAvailableForOperations
										?
										<p className={`${style.paragraph} ${style['paragraph--sub']}`}>
											{ t('other.no_operations_in_cash_register').split('|')[0] }
											<br/>
											{ t('other.no_operations_in_cash_register').split('|')[1] }
										</p>
										:
										<p className={`${style.paragraph} ${style['paragraph--sub']}`}>
											{ t('report.no_operations_found_for_current_date') }
										</p>
									}
								</div>
							</div>
						}
					</div>

					{
						showReportOperationsModal && reportOperationModalInfo.status &&  (
							<NewReportOperationsModal
								closeReportOperationsModal={() => setShowReportOperationsModal(false)}
								reportOperationModalInfo={reportOperationModalInfo}
								printCheck={printCheck}
								resetReportOperationsDuplicate={resetReportOperationsDuplicate}
							/>
						)
					}
				</React.Fragment>
			}
		</React.Fragment>
	)
}

export default NewReportOperations
