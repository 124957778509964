import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from "react-i18next";

import NewPagination from "../NewSettings/NewPagination";
import { SpinnerPrimary } from '../../Common/Spinner';
import CashboxComponent from './CashboxComponent';
import SearchField from './SearchField';
import CompanyComponent from './CompanyComponent';
import MobileNav from '../../MobileNav/MobileNav';
import MobileUserExit from './MobileUserExit';
import Error503Modal from '../Modals/Error500Modal'
import HeaderVersion from '../HeaderVersion/HeaderVersion';
import ArrowDown from "../NewOperations/images/arrowDown.svg?react"
import OutsideClicker from "../NewOperations/helpers/OutsideClicker";
import { KKM_STATUSES_OPTIONS, KKM_STATUS_DEFAULT } from '../../../constants/app';

import s from './companylist.module.scss';
import "../../../Styles/layout/companiesCustom.scss";

const CompaniesList = ({ companies, companyName, copied, znmForCopy, usersCompanyId,
	choosenCompanyId, pending, kkms, selectCompany, copyZnm, openKkm, onlyKkms,
	error, errorData, kkmLoading, balances,
  currentPage, pageSize, total, setPage, onSearchChange, onSearchSubmit, onStatusChange
	 }) => {
  const { t } = useTranslation()
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [status, setStatus] = useState(KKM_STATUS_DEFAULT.id)
  const [debouncedValue] = useDebounce(searchValue, 500)
  const [showStatuses, setShowStatuses] = useState(false)

  useEffect(() => {
    const kkm = localStorage.getItem('KKM');
    if (!kkm && kkms.length > 0) {
      localStorage.setItem('KKM', JSON.stringify(kkms[0]));
    }
  }, [kkms])

  useEffect(() => {
    if (debouncedValue.trim() !== '') {
      handleSearchSubmit();
    }
  }, [debouncedValue]);

  useEffect(() => {
    setSearchValue('');
  }, [choosenCompanyId]);

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue)
    onSearchChange(newValue)
  }

  const handleSearchSubmit = () => {
    onSearchSubmit()
  }

  const handleKkmStatusChange = (statusId) => {
    setStatus(statusId)
    onStatusChange(statusId)
  }

  const getStatusName = () => {
    const statusName = KKM_STATUSES_OPTIONS.find((kkmStatus)=> status === kkmStatus.id).label;
    return t(statusName)
  }

  const kkm = localStorage.getItem('KKM')

  const isSearching = searchValue.trim() !== '';
  const showCashBoxes = kkms.length > 0;
  const noKkmsFound = isSearching && kkms.length === 0 && !kkmLoading;

  return (
    <div className={`${s.wrapper} ${s[`wrapper--${Capacitor.getPlatform()}`]}`}>
      <nav className={s.header}>
        <HeaderVersion
          isActive={true}
          balances={balances}
        />
      </nav>
      <MobileUserExit />
      <main className={s.container}>
        <section className={s.container_top}>
          <div className={!searchOpen ? s.text_top : `${s.text_top} ${s.search_open}`}>
            {!onlyKkms
              ? t('main.select_company_and_cash_register')
              : t('main.select_cash_register')}
          </div>
          <SearchField
            searchValue={searchValue}
            setSearchValue={handleSearchChange}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            placeholder={t('main.search_cash_register')}
            onSearchChange={handleSearchChange}
            onSearchSubmit={handleSearchSubmit}
          />
          {/* filter by status*/}
          <div className={s.selectOptions}>
          <OutsideClicker setShow={setShowStatuses}>
            <div className={s.dropdownWrapper} onClick={() => setShowStatuses(!showStatuses)}>
                <div className={s.dropdown}>
                  <p>{getStatusName()}</p>
                  <ArrowDown />
                </div>
                {showStatuses &&  (
                    <div className={s.dropdownList}>
                      {
                        KKM_STATUSES_OPTIONS.map(kkmStatus => (
                          <div className={s.dropdownItem} key={kkmStatus.label} onClick={() => handleKkmStatusChange(kkmStatus.id)}>
                            {t(kkmStatus.label)}
                          </div>
                        ))
                      }
                    </div>
                  )
                  }
            </div>
          </OutsideClicker>
        </div>
        </section>

        <div className={s.container_sub}>
          {Array.isArray(companies) && companies.length === 0 && (
            <div className="col-md-5">
              <div className="alert alert-secondary" role="alert">
                {t('main.getting_data')}
              </div>
            </div>
          )}

          {error && error.Status < 500 && (
            <div className="col-md-5">
              <div className="alert alert-danger" role="alert">
                {t('main.error')} {errorData.Status}
                <p>{errorData.Message}</p>
              </div>
            </div>
          )}

          {error && error.Status === 503 && <Error503Modal />}

          {!onlyKkms && companies.length > 0 && (
            <section className={`${s.row_container}`}>
              <div className={s.row_desktop_companies}>
                <h6 className={s.row_header}>{t('main.list_of_companies')}</h6>
                <div className={s.row_list}>
                  {companies.map((company, idx) => (
                    <CompanyComponent
                      company={company}
                      idx={idx}
                      choosenCompanyId={choosenCompanyId}
                      kkms={kkms}
                      selectCompany={selectCompany}
                      key={company.Id}
                    />
                  ))}
                </div>
              </div>
            </section>
          )}

          {usersCompanyId !== null && (
            kkmLoading ? (
              <section className={`${s.row_container} ${onlyKkms ? 'companies--translated' : ''}`}>
                <div className={s.row_desktop_cashboxes}>
                  <div className={s.row_header}>{t('main.loading')}</div>
                  <div className={s.row_list}>
                    <div className="company__select">
                      <span className='company__spinner'>
                        <div className="company__name text-center">
                          <SpinnerPrimary />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className={`${s.row_container} ${onlyKkms ? 'companies--translated' : ''}`}>
                <div className={s.row_desktop_cashboxes}>
                  <h6 className={s.row_header}>
                    {isSearching ? t('main.found_cash') : `${t('main.cash_registers')} ${companyName}`}
                  </h6>
                  <div className={s.row_list}>
                    {showCashBoxes &&
                      kkms.map((kkm, idx) => (
                        <CashboxComponent
                          id={kkm.Id}
                          key={kkm.Id}
                          kkm={kkm}
                          openKkm={openKkm}
                          copied={copied}
                          copyZnm={copyZnm}
                          znmForCopy={znmForCopy}
                          pending={pending}
                          idx={idx}
                          expireDate={kkm.ExpiredDate}
                        />
                      ))}

                    {noKkmsFound && (
                      <div className={s.no_results}>
                        <p>{t('main.not_found')}</p>
                      </div>
                    )}

                    {!isSearching && !kkmLoading && kkms.length === 0 && (
                      <div className={s.no_results}>
                        <p>{t('main.no_cash')}</p>
                      </div>
                    )}

                    {showCashBoxes && total > pageSize && (
                      <NewPagination
                        setPage={setPage}
                        activePage={currentPage}
                        total={total}
                        itemsPerPage={pageSize}
                      />
                    )}
                  </div>
                </div>
              </section>
            )
          )}
        </div>
      </main>
      <div className={s.nav}>
        {kkm && (
          <MobileNav
            isActive={true}
            kkm={kkm}
          />
        )}
      </div>
    </div>
  );
};

export default CompaniesList;
