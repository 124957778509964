import React from 'react'
import analytics from '../../styles/pages/shift/analytics.module.scss'
import Statistics from './Statistics'
import TopSales from './TopSales'
import LineChart from './charts/LineChart'
import PieChart from './charts/PieChart'
import { useTranslation } from "react-i18next";

const Analytics = ({ dashboard, updataDashboard, shiftOpen }) => {
  const { t } = useTranslation()

  const newFormatDate = d => {
    let date = new Date(d);
    let h = String(date.getHours()).padStart(2, '0');
    let m = String(date.getMinutes()).padStart(2, '0');
    return h + ':' + m ;
  }

  let data = null
  if (Array.isArray(dashboard.ChartsData.LineChart.Operations)) {
    data = dashboard.ChartsData.LineChart.Operations.map(opt => {
      return {...opt, Time: newFormatDate(opt.Time)}
    })
  }

  return (
    <div className={analytics.analytics}>
      <div className={analytics.statistics}>
        <Statistics
          sells={dashboard.Sells}
          oldSells={dashboard.OldSells}
          refunds={dashboard.Refunds}
          oldRefunds={dashboard.OldRefunds}
          avgCheck={dashboard.AvgCheck}
          oldAvgCheck={dashboard.OldAvgCheck}
          reloadTime={dashboard.Time}
          updataDashboard={() => updataDashboard()}
          newFormatDate={newFormatDate}
        />
        {(data !== null && shiftOpen) ? (
          <LineChart data={data} />
        ) : (
          <p>{ t('kassa.make_operation_to_see_chart') }</p>
        )}
      </div>

      <div className={analytics.blocks}>
        <div className={analytics.sales}>
          <TopSales topSales={dashboard.TopPositions}/>
        </div>

        {
         (data !== null && shiftOpen) ? (
            <div className={analytics.receipts}>
              <div className={analytics.chart}>
                <PieChart data={dashboard.ChartsData.PieChart} />
                <div className={analytics.receiptsTotal}>
                  <p>{ t('kassa.check_for_log') }</p>
                  <p className={analytics.amount}>{dashboard.ChartsData.PieChart.TotalReceipts}</p>
                </div>
              </div>
              <div className={analytics.data}>
                <div className={analytics.row}>
                  <div className={analytics.left}>
                    <div className={analytics.cashBullet}></div>
                    <p>{ t('kassa.cash') }</p>
                  </div>
                  <div className={analytics.right}>
                    <p>{dashboard.ChartsData.PieChart.Cash} ₸</p>
                  </div>
                </div>
                <div className={analytics.row}>
                  <div className={analytics.left}>
                    <div className={analytics.nonCashBullet}></div>
                    <p>{ t('kassa.non_cash') }</p>
                  </div>
                  <div className={analytics.right}>
                    <p>{dashboard.ChartsData.PieChart.NonCash} ₸</p>
                  </div>
                </div>
              </div>
            </div>
         ) : (
            <div className={analytics.receipts}>
              <p>{ t('kassa.make_operation_to_see_chart') }</p>
            </div>
         )
        }
      </div>
    </div>
  )
}

export default Analytics;
